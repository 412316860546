import React, { useRef, useCallback } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Main from "layouts/Main";
import {
  Hero,
  BoldDivider,
  Partners,
  BannerWithButton,
  ImagePlusText,
  OurServices,
  WhyUs,
  RecentWork,
  Feedback,
  Blog,
} from "customComponents";

import AboutUsHomeScreenImage from "customComponents/ImagePlusText/AboutUsHomeScreenImage.png";
import OurMissionImage from "customComponents/ImagePlusText/OurMissionImage.png";

const services = [
  {
    description:
      "Whether you need a new site/mobile app, or just want to make your current one better, we're here to help.",
    title: "Web & Mobile Development",
    avatar: "/images/avatars/WebMobileDevlopment.png",
    btnLink: "/development",
  },
  {
    description:
      "We help you reach your customers and grow your business with digital marketing strategies that work.",
    title: "Digital marketing",
    avatar: "/images/avatars/DigitalMarketing.png",
    btnLink: "/marketing",
  },
  {
    description:
      "We'll manage your social media accounts so you can focus on what matters most to you and your business!",
    title: "Social Media Management",
    avatar: "/images/avatars/SocialMediaManagment_001.png",
    btnLink: "/marketing",
  },
];

const IndexView = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const aboutUsHTML = (
    <div>
      One of the best ways to ensure you're getting a top-notch services is by
      working with a local agency. If you want to work with an agency that's
      dedicated to serving the local community, we're here for you.
      <br />
      <br />
      We're located in Yuba City, California and serve surrounding areas. We
      offer affordable packages that are tailored to fit your needs, no matter
      how big or small!
    </div>
  );
  const aboutUsTitleHTML = (
    <div>
      Local. Affordable
      <br />
      Reliable.
    </div>
  );
  const aboutUsSVG = (
    <Box>
      <Typography
        variant="body1"
        component="a"
        color="text.secondary"
        width="fit-content"
        sx={{
          fontWeight: 700,
        }}
      >
        About Us
      </Typography>
      <svg
        width="74"
        height="5"
        viewBox="0 0 74 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          transform: "translateX(-72px) translateY(3px)",
        }}
      >
        <path
          d="M1 0.5C10.1667 3.5 37.4 7.7 73 0.5"
          stroke="#68C8D6"
          stroke-linecap="round"
        />
      </svg>
    </Box>
  );

  const ourMissionHTML = (
    <div>
      Our mission is to help businesses and organizations achieve their goals by
      providing quality digital solutions. We believe that our clients' success
      is our success, and we go above and beyond to make sure they get what they
      need when they need it.
    </div>
  );
  const ourMissionTitleHTML = (
    <div>
      We help our clients succeed by providing robust and scalable solutions
    </div>
  );
  const ourMissionSVG = (
    <Box>
      <Typography
        variant="body1"
        component="a"
        color="text.secondary"
        width="fit-content"
        sx={{
          fontWeight: 700,
        }}
      >
        Our Mission
      </Typography>
      <svg
        width="95"
        height="5"
        viewBox="0 0 95 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          transform: "translateX(-92px) translateY(3px)",
        }}
      >
        <path
          d="M1 1C12.8403 3.26387 48.0167 6.43329 94 1"
          stroke="#45D2DC"
          stroke-linecap="round"
        />
      </svg>
    </Box>
  );

  const ourServicesSVG = (
    <Box>
      <Typography
        variant="body1"
        component="a"
        color="text.secondary"
        align="center"
        width="fit-content"
        sx={{
          fontWeight: 700,
        }}
      >
        Our Services
      </Typography>
      <svg
        width="103"
        height="5"
        viewBox="0 0 103 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          position: "absolute",
          transform: "translateX(-99px) translateY(15px)",
        }}
      >
        <path
          d="M1 1C13.8588 3.26387 52.0611 6.43329 102 1"
          stroke="#68C8D6"
          strokeLinecap="round"
        />
      </svg>
    </Box>
  );

  const portfolioRef = useRef(null);

  const scrollToPortfolio = useCallback(() => {
    console.log("scrollto");
    if (portfolioRef.current) {
      portfolioRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <Box sx={{ overflowX: "hidden" }}>
      <Main bgcolor={"background.paper"}>
        <Hero scrollToPortfolio={scrollToPortfolio} />
        <BoldDivider inLineText="Some of the technologies we are experts in" />
        <Partners />
        <BannerWithButton
          text1="Schedule a quick call with our team."
          text2="We're excited to see what you have in mind!"
          buttonText="Schedule Call"
          buttonAltText="Free Consultation"
          linkUrl="/schedule-call"
        />
        <ImagePlusText
          upperSVG={aboutUsSVG}
          title={aboutUsTitleHTML}
          mainText={aboutUsHTML}
          imageSrc={AboutUsHomeScreenImage}
          btnLink="/about"
          btnText="LEARN MORE"
          showButton={true}
          imageSizeLarge={700}
          imageSizeSmall={350}
          yPaddingLarge={10}
          yPaddingSmall={6}
        />
        <OurServices
          upperSVG={ourServicesSVG}
          title="Services Provided For You."
          showButton={true}
          services={services}
          noBoxBackground={true}
          mdSize={4}
          cardsPadding={2}
        />
        <WhyUs />
        <ImagePlusText
          upperSVG={ourMissionSVG}
          title={ourMissionTitleHTML}
          mainText={ourMissionHTML}
          imageSrc={OurMissionImage}
          fullScreenFlexDirection="row"
          showButton={true}
          imageSizeLarge={700}
          imageSizeSmall={350}
          yPaddingLarge={10}
          yPaddingSmall={6}
          btnLink="/about"
          btnText="LEARN MORE"
        />
        <BannerWithButton
          text1="Schedule a quick call with our team."
          text2="We're excited to see what you have in mind!"
          buttonText="Schedule Call"
          buttonAltText="Free Consultation"
          linkUrl="/schedule-call"
        />
        {/* <RecentWork ref={portfolioRef} /> */}
        <Feedback  />
        <Blog />
        <BannerWithButton
          text1="Schedule a quick call with our team."
          text2="We're excited to see what you have in mind!"
          buttonText="Schedule Call"
          buttonAltText="Free Consultation"
          linkUrl="/schedule-call"
        />
      </Main>
    </Box>
  );
};

export default IndexView;
